<template>
    <div class="page">
        <van-nav-bar title="企业内刊" left-text="返回" left-arrow @click-left="back"></van-nav-bar>
    <pdf ref="pdf"
      :src="src" 
      :page="currentPage" 
      @num-pages="pageCount=$event" 
      @page-loaded="currentPage=$event" 
      @error="handleError"
      @loaded="loadPdfHandler">
      > 
    </pdf>
    <div class="control_bar">
      <div>
      <van-image :src="require('../../assets/img/larger.png')" width="30" height="30"
        :class="{select:idx==0}"
                @touchstart="idx=0"
                @touchend="idx=-1"
                @click="scaleD"/>
      </div>
      <div style="margin-top:10px;" class="van-hairline--top">
      <van-image :src="require('../../assets/img/small.png')" width="30" height="30"
          :class="{select:idx==1}"
                  @touchstart="idx=1"
                  @touchend="idx=-1"
                  @click="scaleX"/>
      </div>
    </div>
    <van-row class="toolBar" type="flex" justify="center">
      <van-col span="8" style="text-align: center;"><a @click="changePdfPage(0)" :disabled="currentPage==1">上一页</a></van-col>
      <van-col span="8" style="text-align: center;"><span>{{currentPage}} / {{pageCount}}</span></van-col>
      <van-col span="8" style="text-align: center;"><a @click="changePdfPage(1)" :disabled="currentPage==pageCount">下一页</a></van-col>
    </van-row>
    
  </div>
</template>

<script>
import pdf from 'vue-pdf'
var headers = {

    'Authorization': 'Bearer SOME_TOKEN',

    'x-ipp-device-uuid': 'SOME_UUID',

    'x-ipp-client': 'SOME_ID',

    'x-ipp-client-version': 'SOME_VERSION'

};

var loadingTask = pdf.createLoadingTask({
    url:'/upload/640ec40aef21428995607ffb5bde98c4.pdf',
    httpHeaders:headers
});
  export default {
    components: {pdf},
    data () {
      return {
        serverFname:null,
        fileName:null,
        file:null,
        currentPage: 1, // pdf文件页码
        pageCount: 0, // pdf文件总页数
        fileType: 'pdf', // 文件类型　　　　　
        src: null, //'/upload/1d43c8c07f5944a9a1dd9e5e0197aa00.pdf', // pdf文件地址，
        scale: 100, //放大系数
        idx: -1,
      }
    },　　
    created(){
      // this.src = this.$router.query.serverFname;
      
　　},
    methods: {
      handleError(e) {
        this.$toast.clear();
        this.$toast.fail('加载失败:'+e);

      },
      // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
      changePdfPage (val) {
        // console.log(val)
        if (val === 0 && this.currentPage > 1) {
          this.currentPage--
          // console.log(this.currentPage)
        }
        if (val === 1 && this.currentPage < this.pageCount) {
          this.currentPage++
          // console.log(this.currentPage)
        }
      },
      // pdf加载时
      loadPdfHandler (e) {
        this.currentPage = 1 // 加载的时候先加载第一页
        this.$toast.clear();
      },
      back() {
                this.$back();
            },
            //放大
      scaleD() {
        this.scale += 30;
        this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
      },
 
      //缩小
      scaleX() {
        if (this.scale == 100) {
          return;
        }
        this.scale += -30;
        this.$refs.pdf.$el.style.width = parseInt(this.scale) + "%";
      },
    },
    mounted(){
      const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0,
                    message:'加载中'
                });
      // this.file = JSON.parse(this.$route.query);
      // console.log('file==='+JSON.stringify(this.file));
      loadingTask = pdf.createLoadingTask({
          url:this.$route.query.serverFname,
          httpHeaders:headers
      });
      this.src = loadingTask;
      this.serverFname = this.$route.query.serverFname;
      this.fileName = this.$route.query.fileName;
    }
  }

</script>

<style>
.toolBar {
    position:fixed; bottom:0;left:0; width:100%;
    padding:10px;
    background:#ddd;
}
.control_bar {
  float:right;
  position:fixed;
  bottom:80px;
  right:0px;
  margin-right:3px; 
  background:#ddd;
  opacity:0.4;
  filter:alpha(opacity=40); 
  border-radius:3px;
  padding:3px;
}
</style>

